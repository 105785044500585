import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';
import Galery from './Galery'


const Profile = () => {
 const size = UseWindowSize();
 const { height, width } = size;
 const value = height < width ? height : width;
 return (
  <div>
   <Navbar valueSize={value} />
   <Galery />
   <Footer />
  </div>
 )
}

export default Profile