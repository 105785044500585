import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';

import blc1 from '../../assets/images/blockboard/1.jpg'
import blc2 from '../../assets/images/blockboard/2.jpg'
import blc3 from '../../assets/images/blockboard/3.jpg'
import blc4 from '../../assets/images/blockboard/4.jpg'
import blc5 from '../../assets/images/blockboard/5.jpg'
import blc6 from '../../assets/images/blockboard/6.jpg'
import blc7 from '../../assets/images/blockboard/7.jpg'
import blc8 from '../../assets/images/blockboard/8.jpg'
// import Galery from './Galery'

import solidwood from '../../assets/images/SolidWood/Solid Wood Product.png'
import e2e from '../../assets/images/SolidWood/E2E : S4S.png'


const LVL = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;
  return (
    <div>
      <Navbar valueSize={value} />
      <div className="w-screen flex flex-col items-center ">

        {/* <h1 className="mt-20 sm:mt-28 mb-4 sm:mb-8 text-2xl sm:text-4xl font-bold ">LVL</h1>
        <h2 className="mt-2 text-base sm:text-xl">All size and All grade</h2>


        <div className="">
          <div className="p-4 flex flex-col sm:flex-row w-[100%] mt-6 sm:mt-20">
            <div className="mb-4 sm:mr-4">
              <img className="object-contain" src={blc5} alt="" />
            </div >
            <div className="mb-4">
              <img className="object-contain" src={blc7} alt="" />
            </div >
          </div>
        </div> */}

        <div className="w-full flex justify-center mt-24">
          <img className="object-fill" src={solidwood} alt="" />
        </div>
        <div className="w-full flex justify-center mt-10">
          <img className="object-fill" src={e2e} alt="" />
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default LVL