import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

// import { setCurrentSlide } from 'store/slidesSlice'
import { captionAnimation, cardAnimation } from '../../../utils/animations'
import { Caption, Figure } from './GalleryCard.styles'
import GaleryModal from '../../Modal/GaleryModal'
import { useState } from 'react'

const GalleryCard = (
  { id, painting }
  //   {
  //   id,
  //   name,
  //   images,
  //   artist,
  // }
) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    name,
    images,
    artist } = painting

  return (
    <motion.li variants={cardAnimation}>
      <div

        onClick={() => {
          handleOpen()
        }}
      >
        <Figure>
          <img src={images.thumbnail} alt="" />
          <Caption variants={captionAnimation}>
            <h2 className="text-white">{name}</h2>
            <p>{artist.name}</p>
          </Caption>
        </Figure>
      </div>
      <GaleryModal open={open} handleClose={handleClose} data={painting} />
    </motion.li>
  )
}

export default GalleryCard
