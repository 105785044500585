import React from 'react'
import { Link } from "react-router-dom";

import Pic1 from '../../../assets/images/Sengon-bintang-tani-kehutanan-pohon-kayu-berkualtias-bagus-jinjing-jengjeng-jabon-jati-bogor-jakarta-depok-tangerang-bekasi.jpeg'
import Pic2 from '../../../assets/images/Pusat Grosir Kayu Log Albasia Sengon dan Jabon Hutan Rakyat Indonesia.jpeg'

const DiscoverStory = () => {
  return (
    <>

      <div className="min-h-[45vh] flex flex-col justify-center items-center bg-cover" style={{ backgroundImage: `url(${Pic1})` }}>
        <h2 className="text-7xl font-light text-white">
          <span className="uppercase text-9xl text-white">H</span>ikmah
        </h2>
        <h1 className="text-6xl font-bold text-white">Fajar</h1>
      </div>


      <div className="md:p-12 xs:p-4">
        <div className="w-full m-0 py-0 px-0 sm:px-6 text-center flex xs:flex-col md:flex-row">

          <div className="md:w-1/2 md:px-12">
            <h2 className="text-5xl sm:text-7xl font-thin">
              <span className="uppercase text-7xl sm:text-9xl">D</span>iscover
            </h2>
            <h1 className="text-4xl sm:text-6xl font-bold">Our Profile</h1>
            <p className="my-7 ">Hikmah Fajar is enterprise located in Indonesia, with the main office in Surabaya. The company operates in Trade Industry. We established on January 2018, great working relationships with our clients and suppliers, not only provide immediate and reliable services but to continually exceed expectations and goals.</p>
            <p className="my-7 ">We create commercial flows, import and export products, arranging and managing international and domestic projects especially in wood working products with our partners. With 21 years experience, we already have many corporate and manufacturing partners in Indonesia. Also we export any wood working products regularly to country around the world such as Japan, South Korea, Mexico, Singapore, Malaysia,Brunei Darussalam, Thailand, Middle East, U.A.E etc.</p>
            <p className="my-7 ">If you need woodworking specially from Indonesia, just Think CV. HIKMAH FAJAR as the first destination in the search.</p>
            <p className="my-7 ">We will always serve you with high commitment and loyalty.</p>
            {/* <Link to="/" className="uppercase underline text-xl font-medium decoration-amber-600 hover:decoration-yellow-900 text-amber-600 hover:text-yellow-900 tracking-widest">About Us</Link> */}
          </div>

          <div className="md:w-1/2 md:px-12 flex items-center justify-center">
            <img src={Pic2} alt="pic1" />
          </div>

        </div>
      </div>

    </>
  )
}

export default DiscoverStory