import React from 'react'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  return (
    <div className="bg-slate-900 md:p-12 xs:p-1 flex flex-col justify-center items-center text-white">
      <div className="flex flex-col sm:flex-row">
        <div className="px-1 sm:px-6 sm:w-[35%] mt-3 ">
          <h2 className="text-2xl font-medium">About</h2>
          <p className="font-light text-justify mt-3">Hikmah Fajar is a trading company in surabaya indonesia since 2001. We established great working relationships with our clients and suppliers, not only provide immediate and reliable services but to continually exceed expectations and goals.</p>
        </div>
        <div className="px-1 sm:px-6 sm:w-[35%]  mt-3">
          <h2 className="text-2xl font-medium">Product</h2>
          <p className="font-light text-justify mt-3">We are Providing quality products & reliable services and sourcing from reputed manufacturers. Furthermore, we want to elevate dignity of plantation workers.</p>
        </div>
        <div className="px-1 sm:px-6 sm:w-[30%]  mt-3">
          <h2 className="text-2xl font-medium">Contact</h2>

          <div className="flex flex-row mt-3">
            <div className="w-[15%] flex justify-evenly items-center">
              <CallIcon />
            </div>
            <div className="w-[85%]">
              <p className="text-sm text-white ">+62-31-9909-5910</p>
              <p className="text-sm text-white ">(Office)</p>
              <p className="text-sm text-white ">+6281-9344-33334</p>
              <p className="text-sm text-white ">(Mobile / whatsapp no)</p>
              <p className="text-sm text-white ">+65 9690 7435</p>
              <p className="text-sm text-white ">(Representative singapore :  Mr.Paul Tan)</p>
              {/* <p className="text-sm text-white ">Office : +62-31-9909-5910</p>
              <p className="text-sm text-white ">Mobile / whatsapp no: +6281-9344-33334</p>
              <p className="text-sm text-white ">Representative singapore : +65 9690 7435 ( Mr.Paul Tan )</p> */}
            </div>
          </div>

          <div className="flex flex-row mt-3">
            <div className="w-[15%] flex justify-evenly items-center">
              <MailIcon />
            </div>
            <div className="w-[85%]">
              <p className="text-sm  text-white ">cs@hikmah-fajar.com</p>
            </div>
          </div>

          <div className="flex flex-row mt-3">
            <div className="w-[15%] flex justify-evenly items-center">
              <WhereToVoteIcon />
            </div>
            <div className="w-[85%]">
              <p className="text-sm  text-white ">Jl. Raya Dupak 144i, Surabaya, East Java, Indonesia</p>
            </div>
          </div>

        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-center">Copyright ©2022 All rights reserved by Hikmah Fajar</h3>
      </div>
    </div>
  )
}

export default Footer