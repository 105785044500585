import React from 'react'
import { Link } from "react-router-dom";

import Pic1 from '../../../assets/images/imat-bagja-gumilar-jwTvCQQJXh0-unsplash.jpg'
import Pic2 from '../../../assets/images/maximilian-jaenicke-MUrOL8XFJyU-unsplash.jpg'
import IMG_1 from '../../../assets/images/IMG_1.jpg'
import IMG_2 from '../../../assets/images/IMG_2.jpg'
import IMG_3 from '../../../assets/images/IMG_3.jpg'

const DiscoverStory = () => {
 return (
  <>
   <div className="md:p-12 xs:p-4">
    <div className="w-full m-0 py-0 px-6 text-center flex xs:flex-col md:flex-row">

     <div className="md:w-1/2 md:px-12 flex justify-center">
      <div className="grid gap-4 grid-cols-2">
       <img src={Pic1} alt="pic1" />
       <img src={Pic1} alt="pic1" />
       <img src={Pic1} alt="pic1" />
       <img src={Pic1} alt="pic1" />

      </div>
     </div>

     <div className="md:w-1/2 md:px-12">
      <h2 className="text-7xl font-thin">
       <span className="uppercase text-9xl">D</span>iscover
      </h2>
      <h1 className="text-6xl font-bold">Our Product</h1>
      <p className="mt-7 ">We are Providing quality products & reliable services and sourcing from reputed manufacturers. Furthermore, we want to elevate dignity of plantation workers.</p>
      <p className="my-2 ">We could fulfill any wood working products that you need, such as plywood, blockboard, LVL, FJLB, floorbase in any size.</p>
      <p className="mb-7 ">Check it out in our gallery link below</p>
      <Link to="/" className="uppercase underline text-xl font-medium decoration-amber-600 hover:decoration-yellow-900 text-amber-600 hover:text-yellow-900 tracking-widest">Products Gallery</Link>
     </div>

    </div>
   </div>

   <div className="min-h-[45vh] flex flex-col justify-center items-center bg-cover" style={{ backgroundImage: `url(${Pic2})` }}>
    <h2 className="text-7xl font-extralight text-white">
     <span className="uppercase text-9xl text-white">G</span>enuine
    </h2>
    <h1 className="text-6xl font-bold text-white">Wood</h1>
   </div>
  </>
 )
}

export default DiscoverStory