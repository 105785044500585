import { Modal, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import queries from '../../styles/breakpoints'
import UseWindowSize from '../../utils/windowSize';
import CloseIcon from '@mui/icons-material/Close';


const style = {
 position: 'absolute',
 top: '50%',
 left: '50%',
 transform: 'translate(-50%, -50%)',
 bgcolor: '#ffffff',
 border: '2px solid #000000',
 boxShadow: 24,
 p: 4,
};


const GaleryModal = ({ open, handleClose, data }) => {
 const size = UseWindowSize();
 const { height, width } = size;
 return (
  <div>
   <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
   >
    <Box sx={{ ...style, width: width * 0.9, height: height * 0.8 }}>
     <div className="flex flex-row ">
      <div className="w-[40%]">
       <picture>
        <source
         media={queries.tabletUp}
         srcSet={data.images.hero.large}
        />
        <img src={data.images.hero.small} alt="" />
       </picture>
      </div>
      <div className="w-[60%] p-10 text-2xl">
       <h1 className="text-6xl text-black font-bold">
        {data.name}
       </h1>
       <p className="text-[#7D7D7D] text-xl mt-5">
        {data.description}
       </p>

       <p className="text-[#7D7D7D] text-3xl mt-5">
        Artist Name : {data.artist.name}
       </p>
       <p className="text-[#7D7D7D] text-3xl mt-5">
        Year : {data.year}
       </p>
      </div>
      <button className="absolute top-3 right-3" onClick={handleClose}>
       <CloseIcon />
      </button>
     </div>
    </Box>
   </Modal>
  </div >
 )
}

export default GaleryModal