import React from 'react'
import Slider from './Slider'

import DiscoverStory from './DiscoverStory'
import DiscoverProduct from './DiscoverProduct'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'

import UseWindowSize from '../../utils/windowSize'



const Home = () => {
 const size = UseWindowSize();
 const { height, width } = size;
 const value = height < width ? height : width;
 console.log(`height: ${height}, width: ${width}, value: ${value}`);
 return (
  <div>
   <Navbar valueSize={value} />
   <Slider />
   <DiscoverStory />
   <DiscoverProduct />
   <Footer />
  </div>
 )
}

export default Home