import React from 'react'
import { Link } from "react-router-dom";

import Pic1 from '../../../assets/images/Sengon-bintang-tani-kehutanan-pohon-kayu-berkualtias-bagus-jinjing-jengjeng-jabon-jati-bogor-jakarta-depok-tangerang-bekasi.jpeg'
import Pic2 from '../../../assets/images/gambar kantor.jpeg'
import IMG_3 from '../../../assets/images/IMG_3.jpg'

const DiscoverStory = () => {
 return (
  <>
   <div className="md:p-12 xs:p-4">
    <div className="w-full m-0 py-0 px-6 text-center flex xs:flex-col md:flex-row">

     <div className="md:w-1/2 md:px-12">
      <h2 className="text-7xl font-thin">
       <span className="uppercase text-9xl">D</span>iscover
      </h2>
      <h1 className="text-6xl font-bold">Our Story</h1>
      <p className="my-7 ">Hikmah Fajar is a trading company in surabaya indonesia since 2001. We established great working relationships with our clients and suppliers, not only provide immediate and reliable services but to continually exceed expectations and goals</p>
      <Link to="/profile" className="uppercase underline text-xl font-medium decoration-amber-600 hover:decoration-yellow-900 text-amber-600 hover:text-yellow-900 tracking-widest">About Us</Link>
     </div>

     <div className="md:w-1/2 md:px-12 flex justify-center" >
      <img src={Pic2} alt="pic1" className="w-96 h-96 object-cover" />
     </div>

    </div>
   </div>

   <div className="min-h-[45vh] flex flex-col justify-center items-center bg-cover" style={{ backgroundImage: `url(${Pic1})` }}>
    <h2 className="text-7xl font-extralight text-white">
     <span className="uppercase text-9xl text-white">P</span>lanting
    </h2>
    <h1 className="text-6xl font-bold text-white">Planet</h1>
   </div>
  </>
 )
}

export default DiscoverStory