import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';

import oth1 from '../../assets/images/other/other01.jpeg'
// import oth2 from '../../assets/images/other/other02.jpeg'
import oth3 from '../../assets/images/other/other03.jpeg'
import oth4 from '../../assets/images/other/other04.jpeg'

import woodPelet from '../../assets/images/bioEnergy/Wood Pelet.png'
import bbqCoconut from '../../assets/images/bioEnergy/BBQ Coconut.png'
import premiumCoconut from '../../assets/images/bioEnergy/Premium Coconut.png'


const Profile = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;
  return (
    <div>
      <Navbar valueSize={value} />
      <div className=" w-screen flex flex-col items-center ">

        {/* <h1 className="mt-28 mb-4 text-2xl sm:text-4xl font-bold text-center">Chip Block / Pallet Feet / Compressed Sawdust Block</h1>
        <div className="text-center flex flex-col sm:flex-row sm:items-center mt-2">
          <h2 className="sm:mt-1 text-base sm:text-xl font-bold">Application :<span className="text-white">_</span> </h2>
          <h2 className="mt-0 sm:mt-1 text-base sm:text-xl">Pallet Footing / Packaging Component</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Material :</span> Saw dust</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Glue :</span> e2</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Density :</span> 550 - 600 kg/m3</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center mt-2">
          <h2 className="sm:mt-1 text-base sm:text-xl font-bold">Weight :<span className="text-white">_</span> </h2>
          <h2 className="mt-0 sm:mt-1 text-base sm:text-xl">Aprox, 400 g/pcs(size 90x90x90)</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Load Capacity :</span> 1500 - 2000 kg</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center mt-2">
          <h2 className="sm:mt-1 text-base sm:text-xl font-bold">Size :<span className="text-white">_</span> </h2>
          <h2 className="mt-0 sm:mt-1 text-base sm:text-xl">138x88; 95x95; 90x90; (Other size vailable with minimum order quantity)</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Thickness :</span> Uppon reqest</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">MOQ :</span> 1x40ft (40-42 m3)</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl"><span className="font-bold">Delivery Time :</span> 14 days</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl "><span className="font-bold">Shipping Time :</span> 14 days</h2>
        </div>
        <div className="text-center flex flex-col sm:items-center mt-2 mb-8">
          <h2 className="sm:mt-1 text-base sm:text-xl font-bold">Advantages :<span className="text-white">_</span> </h2>
          <h2 className="mt-0 sm:mt-1 text-base sm:text-xl ">Strong horizontal bearing strength and nail holding , Free fumigation (ISPM 15), Eco friendly, Long lasting, Pollution proof, No Seasonal effect</h2>
        </div>


        <div className="p-2 flex flex-col sm:flex-row w-[100%]">
          <div className="mr-2">
            <img className="object-contain" src={oth1} alt="" />
          </div >
          <div className="mr-2">
            <img className="object-contain" src={oth3} alt="" />
          </div >
          <div >
            <img className="object-contain" src={oth4} alt="" />
          </div >
        </div> */}

        <div className="w-full flex justify-center mt-24">
          <img className="object-fill" src={woodPelet} alt="" />
        </div>
        <div className="w-full flex justify-center mt-10">
          <img className="object-fill" src={bbqCoconut} alt="" />
        </div>
        <div className="w-full flex justify-center mt-10">
          <img className="object-fill" src={premiumCoconut} alt="" />
        </div>


      </div>
      <Footer />
    </div >
  )
}

export default Profile