import React from 'react';
import UnderMaintenancePic from '../../assets/icons/underMaintenance.png';
import HikmahFajarLogo from '../../assets/hikmah-fajar-logo.jpg'

const UnderMaintenance = () => {
 return (
  <div className="h-screen flex flex-col items-center justify-center ">
   <div className="w-[30%] sm:w-[50%] md:w-[70%] xs:w-[90%] flex flex-col items-center justify-center text-center">
    <img className="xs:h-24 sm:h-28  md:h-30 sm:mt-7 " src={HikmahFajarLogo} alt="Hikmah Fajar" />
    <img
     className="xs:h-36 sm:h-48 md:h-64 h-80 mt-10"
     src={UnderMaintenancePic}
     alt="under maintenance"
    />
    <h1 className="mt-5 xs:text-lg sm:text-2xl md:text-3xl font-bold">Under Maintenance</h1>
    <h3 className="mt-4 mb-12 xs:text-sm sm:text-lg md:text-xl">
     The page you're looking for is currently under maintenance and will be
     back soon
    </h3>
   </div>
  </div>
 );
};

export default UnderMaintenance;
