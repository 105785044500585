import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';

import blc1 from '../../assets/images/blockboard/1.jpg'
import blc2 from '../../assets/images/blockboard/2.jpg'
import blc3 from '../../assets/images/blockboard/3.jpg'
import blc4 from '../../assets/images/blockboard/4.jpg'
import blc5 from '../../assets/images/blockboard/5.jpg'
import blc6 from '../../assets/images/blockboard/6.jpg'
import blc7 from '../../assets/images/blockboard/7.jpg'
import blc8 from '../../assets/images/blockboard/8.jpg'
// import Galery from './Galery'

import figureJoin from '../../assets/images/FigureJoin/Figure Join.png'


const Blockboard = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;
  return (
    <div>
      <Navbar valueSize={value} />
      <div className="w-screen flex flex-col items-center ">

        {/* <h1 className="mt-20 sm:mt-28 mb-4 sm:mb-8 text-2xl sm:text-4xl font-bold ">Blockboard</h1>
        <h2 className="mt-2 text-base sm:text-xl">All size and All grade</h2>

        <div className="">
          <div className="p-4 flex flex-col sm:flex-row w-[100%] mt-6 sm:mt-20">
            <div className="mb-4 sm:mr-4">
              <img className="object-contain" src={blc1} alt="" />
            </div >
            <div className="mb-4 ">
              <img className="object-contain" src={blc2} alt="" />
            </div >
          </div>
        </div> */}

        <div className="w-full flex justify-center mt-20">
          <img className="object-fill" src={figureJoin} alt="" />
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Blockboard