import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';

import ply1 from '../../assets/images/plywood/ply1.jpg'
import ply2 from '../../assets/images/plywood/ply2.jpg'
import ply3 from '../../assets/images/plywood/ply3.jpg'
import ply4 from '../../assets/images/plywood/ply4.jpg'
import ply5 from '../../assets/images/plywood/ply5.jpg'
import ply6 from '../../assets/images/plywood/ply6.jpg'
// import Galery from './Galery'

import blc1 from '../../assets/images/blockboard/1.jpg'
import blc2 from '../../assets/images/blockboard/2.jpg'

import lvl from '../../assets/images/lvl/LVL_jadi.png'
import filmFace from '../../assets/images/panelProduct/Film Face.png'


const Profile = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;
  return (
    <div>
      <Navbar valueSize={value} />
      <div className=" w-screen flex flex-col items-center ">
        {/* <div className="w-screen flex flex-col justify-center p-4">
     <div className=" p-4"> */}
        <h1 className="mt-28 mb-4 text-2xl sm:text-4xl font-bold ">Plywoods</h1>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-3 text-base sm:text-xl font-bold">Grade : <span className="text-white">_</span> </h2>
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl">BBCC, uty Better, uty Eksport, uty Local, uty Reject</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-3 text-base sm:text-xl font-bold">Size : <span className="text-white">_</span></h2>
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl">2.7mm ; 4.6mm ; 6.5mm ; 7.5mm ; 8.5mm ; 14.5mm</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-3 text-base sm:text-xl font-bold">Glue : <span className="text-white">_</span></h2>
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl">e2, e1, e0, Carb, MR</h2>
        </div>
        <div className="text-center flex flex-col sm:flex-row sm:items-center">
          <h2 className="mt-3 text-base sm:text-xl font-bold">Wood type : <span className="text-white">_</span></h2>
          <h2 className="mt-1 sm:mt-3 text-base sm:text-xl">Albasia Falcata, MLH, semi MLH, etc</h2>
        </div>


        {/* </div>

    </div> */}
        {/* <div className=" bg-green-400"> */}
        <div className="p-2 flex flex-row w-[100%]">
          <div className="mr-2">
            <img className="object-contain" src={ply1} alt="" />
          </div >
          <div className="mr-2">
            <img className="object-contain" src={ply2} alt="" />
          </div >
          <div >
            <img className="object-contain" src={ply3} alt="" />
          </div >
        </div>
        <div className="p-2 flex flex-row w-[100%]">
          <div className="mr-2">
            <img className="object-contain" src={ply4} alt="" />
          </div >
          <div className="mr-2">
            <img className="object-contain" src={ply5} alt="" />
          </div >
          <div >
            <img className="object-contain" src={ply6} alt="" />
          </div >
        </div>
        {/* </div> */}
      </div>
      {/* <Galery /> */}

      <div className="w-screen flex flex-col items-center ">
        {/* <div className="w-[40%] flex flex-col items-center p-4"> */}
        <h1 className="mt-20 sm:mt-28 mb-4 sm:mb-8 text-2xl sm:text-4xl font-bold ">Blockboard</h1>
        <h2 className="mt-2 text-base sm:text-xl">All size and All grade</h2>

        {/* </div> */}
        <div className="">
          <div className="p-4 flex flex-col sm:flex-row w-[100%] mt-6 sm:mt-20">
            <div className="mb-4 sm:mr-4">
              <img className="object-contain" src={blc1} alt="" />
            </div >
            <div className="mb-4 ">
              <img className="object-contain" src={blc2} alt="" />
            </div >

          </div>

        </div>
      </div>
      <div className="w-full flex justify-center">
        <img className="object-contain" src={lvl} alt="" />
      </div>
      <div className="w-full flex justify-center">
        <img className="object-contain" src={filmFace} alt="" />
      </div>

      <Footer />
    </div>
  )
}

export default Profile