import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Undermaintenance from './pages/Undermaintenance'
import Home from './pages/Home';
import ContactUs from "./pages/Contact";
import Profile from "./pages/Profile";
import Product from "./pages/Product"
import Products from "./pages/Products"
import FigureJoin from "./pages/FigureJoin"
import SolidWood from "./pages/SolidWood"
import BioEnergy from "./pages/BioEnergy"
import Furniture from "./pages/Furniture"

function App() {
  return (
    <BrowserRouter>
      <div className="">
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="undermaintenance" element={<Undermaintenance />} />
          </Route>
          <Route path="contact" element={<ContactUs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="product" element={<Product />} />
          <Route path="products" element={<Products />} />
          <Route path="figurejoin" element={<FigureJoin />} />
          <Route path="solidwood" element={<SolidWood />} />
          <Route path="bioenergy" element={<BioEnergy />} />
          <Route path="furniture" element={<Furniture />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
