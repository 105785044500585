import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';

import fur03 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0003.jpg'
import fur04 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0004.jpg'
import fur05 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0005.jpg'
import fur06 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0006.jpg'
import fur07 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0007.jpg'
import fur08 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0008.jpg'
import fur09 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0009.jpg'
import fur10 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0010.jpg'
import fur11 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0011.jpg'
// import fur12 from '../../assets/images/furniture/Katalog 2023 FERVANT WOOD_page-0012.jpg'


const Profile = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;
  return (
    <div>
      <Navbar valueSize={value} />
      <div className=" w-screen flex flex-col items-center ">

        <div className="w-10/12 sm:w-1/3 flex justify-center mt-24">
          <img className="object-fill" src={fur03} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur04} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur05} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur06} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur07} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur08} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur09} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur10} alt="" />
        </div>
        <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur11} alt="" />
        </div>
        {/* <div className="w-10/12 sm:w-1/3 flex justify-center mt-10">
          <img className="object-fill" src={fur12} alt="" />
        </div> */}


      </div>
      <Footer />
    </div >
  )
}

export default Profile