import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Logo from '../../assets/hikmah-fajar-logo.jpg'

const Navbar = ({ valueSize }) => {

  const renderSize = (widthWindow) => {
    switch (widthWindow) {
      case widthWindow < 500:
        console.log('<500')

        return widthWindow * 0.02

      default:
        break;
    }
  }

  return (
    <div
      className="header"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }
      }
    >
      <div className="header__logo">
        <Link to="/">
          <img
            className="header__logoImg"
            src={Logo}
            alt=""
            style={{ width: valueSize * 0.08 }}
          />
        </Link>
      </div>
      <div className="header__links">

        <div className="header__links">

          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/profile">
            ABOUT US
          </Link>
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/products">
            PANEL PRODUCT
          </Link>
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/figurejoin">
            FIGUR JOIN PRODUCT
          </Link>
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/solidwood">
            SOLID WOOD PRODUCT
          </Link>
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/bioenergy">
            BIO ENERGY PRODUCT
          </Link>
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/furniture">
            FURNITURE PRODUCT
          </Link>
          {/* <Link
      // style={{ fontSize: renderSize(valueSize) }}
      to="/product">
      OUR PRODUCT
     </Link> */}
          <Link
            // style={{ fontSize: renderSize(valueSize) }}
            to="/contact"
          >
            CONTACT US
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Navbar