import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Navbar from '../../components/Navbar';
import UseWindowSize from '../../utils/windowSize';
import Footer from '../../components/Footer';



const ContactUs = () => {
  const size = UseWindowSize();
  const { height, width } = size;
  const value = height < width ? height : width;

  return (
    <div className="overflow-hidden box-border block">
      {/* Header */}
      <Navbar valueSize={value} />

      <div className="w-full px-1 sm:px-3 pt-20 sm:pt-28 mx-auto pb-10">
        <div className="text-center pb-3 sm:pb-7">
          <h2 className="text-xl sm:text-3xl font-bold uppercase pb-5 relative text-[#37517e]
          before:content-[''] after:content-['']
          before:absolute after:absolute
          before:block after:block
          before:w-[120px] after:w-10
          before:h-[1px] after:h-[3px]
          before:bg-[#ddd] after:bg-[#47b2e4]
          before:bottom-[1px] after:bottom-0
          before:left-[calc(50%_-_60px)] after:left-[calc(50%_-_20px)]
          ">Contact Us</h2>

        </div>

        {/* <div className="flex flex-wrap"> */}
        <div className="items-stretch flex shrink-0 w-full px-0 sm:px-3 mb-1">
          <div className="border-t-[3px] border-b-[3px] border-[#47b2e4] p-3 sm:p-7 bg-white w-full shadow">

            <div className="flex flex-col sm:flex-row w-full">

              <div className="flex flex-col items-center sm:w-[40%] mb-5">
                <i className="text-xl text-[#47b2e4] float-lef min-w-[44px] min-h-[44px] w-11 h-11 bg-[#e7f5fb] flex justify-center items-center rounded-full">
                  <WhereToVoteIcon />
                </i>
                <h4 className="text-xl sm:text-2xl font-semibold mb-1 text-[#37517e]">Location:</h4>
                <p className="text-sm h-full flex items-center text-[#6182ba] text-center">
                  Jl. Raya Dupak 144i, Surabaya, East Java, 60172 Indonesia
                </p>
              </div>

              <div className="flex flex-col items-center sm:w-[30%] mb-5">
                <i className="text-xl text-[#47b2e4] float-lef min-w-[44px] min-h-[44px] w-11 h-11 bg-[#e7f5fb] flex justify-center items-center rounded-full">
                  <MailIcon />
                </i>
                <h4 className="text-xl sm:text-2xl font-semibold mb-1 text-[#37517e]">Email:</h4>
                <p className="text-sm h-full flex items-center text-[#6182ba] text-center">cs@hikmah-fajar.com</p>
              </div>

              <div className="flex flex-col items-center sm:w-[30%] mb-5">
                <div className="flex felx-row justify-between">
                  <i className="text-xl text-[#47b2e4] float-lef min-w-[44px] min-h-[44px] w-11 h-11 mr-1 bg-[#e7f5fb] flex justify-center items-center rounded-full">
                    <CallIcon />
                  </i>
                  <i className="text-xl text-[#47b2e4] float-lef min-w-[44px] min-h-[44px] w-11 h-11 bg-[#e7f5fb] flex justify-center items-center rounded-full">
                    <WhatsAppIcon />
                  </i>

                </div>
                <h4 className="text-xl sm:text-2xl font-semibold mb-1 text-[#37517e]">Phone & Whatsapp:</h4>
                <p className="text-sm  text-[#6182ba] text-center">Office : +62-31-9909-5910</p>
                <p className="text-sm  text-[#6182ba] text-center">Mobile / whatsapp no: +6281-9344-33334</p>
                <p className="text-sm  text-[#6182ba] text-center">Representative Singapore : +65 9690 7435 ( Mr.Paul Tan )</p>
              </div>
            </div>

            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2306654660865!2d106.82094481476928!3d-6.233294495487948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3e14e75e635%3A0x17cac5e60899157b!2sPatra%20Jasa%20Office%20Tower!5e0!3m2!1sen!2sid!4v1639038943456!5m2!1sen!2sid"
              style={{ border: 0, width: '100%', height: 290 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe> */}

            <iframe
              title="This is a unique title"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1821.034120365774!2d112.72226146312296!3d-7.245084394350051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f936fee8d417%3A0x55fa269117cbff65!2sJl.%20Dupak%20No.144%2C%20Gundih%2C%20Kec.%20Bubutan%2C%20Kota%20SBY%2C%20Jawa%20Timur%2060171!5e0!3m2!1sen!2sid!4v1647785197686!5m2!1sen!2sid"
              style={{ border: 0, width: '100%', height: 390 }}
              allowfullscreen=""
              loading="lazy">
            </iframe>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
