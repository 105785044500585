import { v4 as uuidv4 } from "uuid";

const dataSlider = [
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
 {
  id: uuidv4(),
  title: "Lorem ipsum",
  subTitle: "Lorem"
 },
];

export default dataSlider;